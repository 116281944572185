<template>
  <div class="container box">
    <div>
      <h3 class="text-success">Analysis</h3>
    </div>
    <hr />
    <div>
      <div class="row select">
        <div class="col-md-3">
          <div class="text-right select-title">
            考卷類型:
          </div>
        </div>
        <div class="col-md-9">
          <el-select v-model="testType">
            <el-option label="TOEFL" value="TOEFL"></el-option>
            <el-option label="SAT" value="SAT"></el-option>
            <el-option label="ACT" value="ACT"></el-option>
            <el-option label="Practices" value="Practices"></el-option>
          </el-select>
        </div>
      </div>
      <div class="row select">
        <div class="col-md-3">
          <div class="text-right select-title">
            <b
              class="text-success"
              v-if="testType !== ''"
              style="margin:0 5px;display:inline-block"
              >{{ testType }}</b
            >考卷:
          </div>
        </div>
        <div class="col-md-9">
          <el-select
            v-model="test"
            placeholder="選擇考卷"
            filterable
            class="select-test"
          >
            <el-option
              v-for="test in tests"
              :key="test.id"
              :label="test.full_name + ' (' + test.title + ')'"
              :value="test.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="row select">
        <div class="col-md-3">
          <div class="text-right select-title">
            學生:
          </div>
        </div>
        <div class="col-md-9">
          <el-radio v-model="users" label="0" border @change="teacherId = null">所有学生</el-radio>
          <!-- <el-radio v-model="users" label="1" border>班級學生</el-radio> -->
          <el-radio v-model="users" label="2" border>老師的學生</el-radio>
        </div>
      </div>
      <div class="row select" v-if="users==='1'">
        <div class="col-md-3">
          <div class="text-right select-title">
            選擇班級:
          </div>
        </div>
        <div class="col-md-9">
          <el-select
            v-model="test"
            placeholder="選擇班級"
            filterable
            class="select-test"
          >
            <el-option
              v-for="test in tests"
              :key="test.id"
              :label="test.full_name + ' (' + test.title + ')'"
              :value="test.title"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="row select" v-if="users==='2'">
        <div class="col-md-3">
          <div class="text-right select-title">
            選擇老師:
          </div>
        </div>
        <div class="col-md-9">
          <TeacherSelector :value="teacherId" @change="editor => selectedEditor(editor)" />
        </div>
      </div>
      <div class="row select">
        <div class="col-md-3">
          <div class="text-right select-title">
            日期:
          </div>
        </div>
        <div class="col-md-9">
          <el-date-picker
            v-model="dateArr"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div>
      <div class="row select">
        <div class="col-sm-3"></div>
        <div class="col-sm-9">
          <el-button type="success" @click="getAnalysis">查看考卷分析</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import Tests from "@/apis/tests.js";
import {
  StudentSelector,
  TeacherSelector,
  SessionClassSelector
} from "@/components/selector";

export default {
  metaInfo() {
    return {
      title: "Analysis - Ivy-Way Academy"
    };
  },

  components: {
    TeacherSelector
  },

  mixins: [],

  props: [],
  data() {
    return {
      testType: null,
      tests: "",
      test: null,
      users: "0",
      dateArr: null,
      teacherId: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一個月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三個月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  computed: {},
  watch: {
    testType() {
      this.getTests();
    }
  },

  mounted() {
    if (this.$route.query.type) {
      this.testType = this.$route.query.type;
    };
    if (this.$route.query.test) {
      if(this.testType !== "TOEFL"){
        this.test = parseInt(this.$route.query.test);
      } else {
        this.test = this.$route.query.test;
      }
    };
    if (this.$route.query.teacherId) {
      this.teacherId = parseInt(this.$route.query.teacherId);
      this.users = "2";
    };
    if (this.$route.query.dateArr) {
      this.dateArr = this.$route.query.dateArr;
    };
  },

  methods: {
    async getTests() {
      let tests = {};
      if (this.testType == "SAT") {
        tests = await Tests.getSats();
        tests = tests["sats"];
      } else if (this.testType == "ACT") {
        tests = await Tests.getActs();
      } else if (this.testType == "TOEFL") {
        tests = await Tests.getToefl();
        tests = tests.toefls;
        tests.forEach(test => {
          test["full_name"] = test.title; 
          test["order"] = parseInt(test.title.replace(/[^0-9]/ig,"")); 
        });
        tests = _.orderBy(tests, ["order","asc"]);
      }
      this.tests = tests;
    },
    async selectedEditor(val) {
      this.teacherId = val.id;
      const students = await Tests.getTeacherStudents({
        teacher_user_id:val.id
      });
      this.students = students.students_of_teacher;
      console.log(this.students);
    },
    getAnalysis(){
      if (this.testType !== null && this.test !== null) {
        this.$router.push({
          path: `/analysis/${this.testType.toLowerCase()}`,
          query: {
            type: this.testType,
            test: this.test,
            teacherId: this.teacherId,
            dateArr: this.dateArr
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.box {
  padding: 20px 0;
}
.select {
  margin-top: 20px;
}
.select-title {
  line-height: 40px;
}
::v-deep .el-select,::v-deep .el-date-editor  {
  width: 100%;
}
hr {
  border-top: 2px solid #42a16a;
}
</style>
