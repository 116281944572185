<template>
  <div class="analysis container">
    <FilterTest />
    <hr />
    <div class="row sat">
      <div class="col-md-5 sat_left print_div">
        <div>
          <p>TOEFL</p>
          <span><i class="fa fa-registered" aria-hidden="true"></i></span>
        </div>
      </div>
      <div class="col-md-7 sat_right row">
        <div class="col-md-8">
          <ul>
            <li>考卷: <b></b></li>
            <li v-if="teacherId > 0">
              老師:
            </li>
            <li v-if="dateArr !== null">
              日期: <b>{{ dateArr[0] }} ~ {{ dateArr[1] }}</b>
            </li>
          </ul>
        </div>
        <div class="col-md-4 img" style="line-height:93px;">
          <img
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
          />
        </div>
      </div>
    </div>
    <div v-if="analysis">
      <TOEFLAnalysis
        :tests="tests"
        :analysis="analysis"
        :avgScore="user_score"
      />
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Tests from "@/apis/tests.js";
import FilterTest from "@/views/analysis/Index";
import TOEFLAnalysis from "@/views/sessionClasses/analysis/components/TOEFL";

export default {
  metaInfo() {
    return {
      title: "Analysis - Detail - Ivy-Way Academy"
    };
  },

  components: {
    FilterTest,
    TOEFLAnalysis
  },

  mixins: [],

  props: [],
  data() {
    return {
      testType: null,
      test: null,
      dateArr: null,
      teacherId: null,
      user_score: {},
      tests: [],
      analysis: {}
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$route.query.type) {
      this.testType = this.$route.query.type;
    }
    if (this.$route.query.test) {
      this.test = this.$route.query.test;
    }
    if (this.$route.query.teacherId) {
      this.teacherId = parseInt(this.$route.query.teacherId);
      this.users = "2";
    }
    if (this.$route.query.dateArr) {
      this.dateArr = this.$route.query.dateArr;
    }
    this.getAnalysis();
  },
  methods: {
    async getAnalysis() {
      let user_ids = null;
      if (this.teacherId > 0) {
        await Tests.getTeacherStudents({
          teacher_user_id:this.teacherId
        });
      }
      const res = await Tests.getAnalysis({
        toefl_id: this.test,
        user_ids: user_ids,
        date_start: this.dateArr !== null ? this.dateArr[0] : null,
        date_end: this.dateArr !== null ? this.dateArr[1] : null
      });
      this.analysis["scores"] = res.user_exams;
      this.tests = res.toefl_questions;
      this.user_score.reading_score = res.avg_scores[0].reading_avg_score;
      this.user_score.listening_score = res.avg_scores[0].listening_avg_score;
      this.user_score.speaking_score = res.avg_scores[0].speaking_avg_score;
      this.user_score.writing_score = res.avg_scores[0].writing_avg_score;
    }
  }
};
</script>

<style scoped>
hr {
  border-top: 2px solid #42a16a;
}
.analysis {
  padding: 20px;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 420px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: #43a06b;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat_right img {
  width: 90%;
}
</style>
